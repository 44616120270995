/* You can add global styles to this file, and also import other style files */
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import 'drawflow/dist/drawflow.min.css';
@import 'grapesjs/dist/css/grapes.min.css';

.pointer:hover {
    cursor: pointer;
}

* {
    font-family: 'Poppins', sans-serif;
}
::ng-deep * {
    font-family: 'Poppins', sans-serif;
}

.bg-light {
    background-color: #f8f8f8 !important;
}

.introjs-tooltip {
    border-radius: 16px !important;
    a {
        border-radius: 16px !important;
    }
}

*::-webkit-scrollbar {
    width: 0px !important;
    background-color: white !important;
}

*::-webkit-scrollbar-thumb {
    width: 0px !important;
    background-color: whitesmoke !important;
    border-radius: 0px !important;
}

*::-webkit-scrollbar:horizontal {
    width: 0px !important;
    background-color: white !important;
}

*::-webkit-scrollbar-thumb:horizontal {
    width: 0px !important;
    background-color: whitesmoke !important;
    border-radius: 0px;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.7) !important; /* Adjust transparency and color */
}